import React, { ReactElement, useState } from "react";
import { Box, useBoolean } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import { AuthModal } from "../../components/auth/AuthModal";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { NPCForm } from "../../components/create_homebrew/npc/NPCForm";
import { uploadNPCPdf } from "../../components/create_homebrew/pdfgeneration/NpcPdf";
import { Break } from "../../components/styled/Break";
import { Title } from "../../components/styled/Text";
import { NPCEntity, defaultNPCEntity, generateNPCMonster } from "../../constants/npc/NPC";
import { useApiClient } from "../../context/ApiClient";
import { useAuthState } from "../../context/AuthState";
import { generateS3Key } from "../../Utils";
import { COLLECTIONS } from "../../constants/General";
import { create, createEntity, getEntity } from "../../api/common";
import { createMonsterHelper } from "../../constants/npc/Archetypes";
import { MonsterEntity } from "../../constants/Monster";

export default function NPC(): ReactElement {
  const [title, setTitle] = useState("NPC Generator BETA");
  const [alert, setAlert] = useState(<div />);
  const [showSignUpForm, setShowSignUpForm] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const authState = useAuthState();
  const apiClient = useApiClient().getClient();

  const onNPCSubmit = async (values: NPCEntity) => {
    if (!authState.loggedIn) {
      setShowSignUpForm.on();
    } else {
      setIsLoading.on();

      const userId = authState.id || "";
      const fileS3Key = generateS3Key(userId, values.name + Math.floor(Math.random() * 1000000), COLLECTIONS.NPCs);
      if (!values.monsterRefId) {
        let monsterData;
        if (values.custom) {
          const isAggressive = Math.random() > 0.5;
          monsterData = createMonsterHelper(values.powerLevel, values.strongAbilities || [], isAggressive, values.fightingStyle!);
        } else {
          monsterData = generateNPCMonster(values);
        }
        const monsterRef = await create(apiClient, COLLECTIONS.Monsters, { data: monsterData });
        values.monsterRefId = monsterRef.ref.id;
      }
      const monsterResponse = await getEntity(apiClient, COLLECTIONS.Monsters, values.monsterRefId!);
      const monsterEntity: MonsterEntity = monsterResponse.data;
      if (values.previewS3Key === "") {
        values.previewS3Key = monsterEntity.previewS3Key;
      }
      await createEntity(apiClient, userId, fileS3Key, COLLECTIONS.NPCs, values);
      await uploadNPCPdf(values, monsterEntity, authState, fileS3Key);
      navigate("/saved/npc");
    }
  };

  return (
    <Box backgroundColor="npc.background" minH="100vh">
      {isLoading ? (
        <div />
      ) : (
        <div>
          <MyNavbar />
          {alert}
          <Break height="5vh" />
          <Box bg="white" m="0 5vw 0 5vw" p="0 3vw 0 3vw">
            <Title text={title} fontSize="2.3rem" />
            <Formik initialValues={{ template: "" }} onSubmit={() => {}}></Formik>
            <Break height="2vh" />
            <NPCForm setTitle={setTitle} setAlert={setAlert} onSubmit={onNPCSubmit} npcEntity={defaultNPCEntity} />
          </Box>
          <AuthModal isOpen={showSignUpForm} onClose={setShowSignUpForm.off} startSignUp={true} />
          <Break height="5vh" />
        </div>
      )}
    </Box>
  );
}
